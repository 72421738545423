var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-line"
  }, [_c('Card', {
    attrs: {
      "padding": 20
    }
  }, [_c('div', {
    staticClass: "title-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("销售数据分析")]), _c('div', [_c('Dropdown', {
    staticClass: "sortby",
    on: {
      "on-click": function ($event) {
        _vm.sortBy = $event;
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.sortBy) + " "), _c('Icon', {
    attrs: {
      "type": "ios-arrow-down"
    }
  })], 1), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', {
    attrs: {
      "name": "按年统计"
    }
  }, [_vm._v("按年统计")]), _c('DropdownItem', {
    attrs: {
      "name": "按月统计"
    }
  }, [_vm._v("按月统计")]), _c('DropdownItem', {
    attrs: {
      "name": "按周统计"
    }
  }, [_vm._v("按周统计")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "data-content"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "important"
  }, [_vm._v("￥1385")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("营业额")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "data"
  }, [_vm._v("186")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("销售量")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "data"
  }, [_vm._v("3.8%")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v("同比增长")])])]), _c('div', {
    staticClass: "chart"
  }, [_c('apexchart', {
    attrs: {
      "type": "line",
      "height": "380",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };