var zh = require("../../../../libs/zh.json");
export default {
  name: "line-chart",
  components: {},
  props: {},
  data() {
    return {
      sortBy: "按年统计",
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#5b73e8", "#dee2e5", "#feaf1a"],
        chart: {
          stacked: false,
          toolbar: {
            show: false
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        stroke: {
          width: [0, 2, 4],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value + "月";
            }
          },
          categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
        },
        yaxis: {
          title: {
            text: "销售量",
            style: {
              color: "#bac2c7"
            }
          },
          labels: {
            show: true,
            style: {
              colors: ["#bac2c7"]
            }
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        legend: {
          offsetY: 5
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "PC端",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 35]
      }, {
        name: "移动端",
        type: "area",
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 47]
      }, {
        name: "平板端",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 45]
      }];
    }
  },
  mounted() {
    this.init();
  }
};